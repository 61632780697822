import * as THREE from "three";

import { BoxLineGeometry } from "three/examples/jsm/geometries/BoxLineGeometry";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";

import { Control } from "./control.js";
import { Config } from "./config.js";
import { Bender } from "./bender.js";

const bender = new Bender();
class XRScene {
  constructor() {
    const container = document.querySelector("#c");
    this.leftEyeElem = document.querySelector("#leftEye");

    this.scene = new THREE.Scene();
    this.models = {};
    this.scene.background = new THREE.Color(0xa0a0a0);
    this.scene.fog = new THREE.Fog(0xa0a0a0, 1000, 1000);
    let axesHelper = new THREE.AxesHelper(5);
    this.scene.add(axesHelper);

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.1,
      10
    );
    this.camera.position.set(-3, 1.5, 1);

    this.leftEyeCamera = new THREE.PerspectiveCamera(
      Config.EYECAM.eyeCamFOV, // fov
      1, // aspect
      0.1, // near
      50 // far
    );
    this.leftEyeCamera.position.set(0.03, 1.7, 0.1); // 左眼坐标
    const leftEyeCameraHelper = new THREE.CameraHelper(this.leftEyeCamera);
    this.scene.add(leftEyeCameraHelper);

    this.rightEyeCamera = new THREE.PerspectiveCamera(
      Config.EYECAM.eyeCamFOV,
      1,
      0.1,
      50
    );
    this.rightEyeCamera.position.set(-0.03, 1.7, 0.1); // 右眼坐标
    const rightEyeCameraHelper = new THREE.CameraHelper(this.rightEyeCamera);
    this.scene.add(rightEyeCameraHelper);

    this.addEnv();
    this.addDataPanel();

    this.addUIElements();
    this.loadScene();

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
    });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(this.renderer.domElement);

    /*
    this.leftEyeControl = new OrbitControls(
      this.leftEyeCamera,
      this.leftEyeElem
    );
    this.leftEyeControl.update();
    */

    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.update();
  }

  animate() {
    // this.renderer.setScissor(0, 0, window.innerWidth, window.innerHeight);
    this.renderer.setViewport(0, 0, window.innerWidth, window.innerHeight);
    this.renderer.render(this.scene, this.camera);

    //render left eye camera
    this.renderer.clearDepth();
    this.renderer.setScissorTest(true);

    this.renderer.setScissor(0, window.innerHeight - 100, 200, 100);
    this.renderer.setViewport(0, window.innerHeight - 100, 200, 100);
    this.renderer.render(this.scene, this.leftEyeCamera);
    this.leftEyeCamera.lookAt(0, 1.5, 1);

    this.renderer.setScissor(200, window.innerHeight - 100, 200, 100);
    this.renderer.setViewport(200, window.innerHeight - 100, 200, 100);
    this.renderer.render(this.scene, this.rightEyeCamera);
    this.rightEyeCamera.lookAt(0, 1.5, 1);

    this.renderer.setScissorTest(false);

    requestAnimationFrame(this.animate.bind(this));
  }
  genDeckGeo() {
    let geo = new THREE.BoxGeometry(
      this.panel._data.deck["Deck Size"],
      this.panel._data.deck["Deck Size"] / this.panel._data.deck["Deck Aspect"],
      Config.DECK.depth,
      20,
      20,
      20
    );
    if (this.panel._data.deck["Deck Bending"] != 0) {
      bender.bend(geo, "y", this.panel._data.deck["Deck Bending"]);
    }
    return geo;
  }
  genDockGeo() {
    let geo = new THREE.BoxGeometry(
      this.panel._data.dock["Dock Size"],
      this.panel._data.dock["Dock Size"] / this.panel._data.dock["Dock Aspect"],
      Config.DOCK.depth
    );
    return geo;
  }
  addUIElements() {
    // reach envelopes
    // left
    const reachGeo = new THREE.SphereGeometry(Config.HUMAN.arm.length, 32, 32);
    const leftReachMat = new THREE.MeshBasicMaterial({
      color: 0x0000ff,
      wireframe: true,
      transparent: true,
      opacity: 0.3,
    });
    this.models["reachLeft"] = new THREE.Mesh(reachGeo, leftReachMat);
    this.models["reachLeft"].position.set(...Config.HUMAN.shoulder.leftPos);
    this.scene.add(this.models["reachLeft"]);

    // right
    const rightReachMat = leftReachMat.clone();
    rightReachMat.color.set(0xff0000);
    this.models["reachRight"] = new THREE.Mesh(reachGeo, rightReachMat);
    this.models["reachRight"].position.set(...Config.HUMAN.shoulder.rightPos);
    this.scene.add(this.models["reachRight"]);

    // deck
    const deckGeo = this.genDeckGeo();
    const deckMat = new THREE.MeshBasicMaterial({
      color: 0xffffff,
    });
    this.models["deck"] = new THREE.Mesh(deckGeo, deckMat);
    this.models["deck"].position.set(...Config.DECK.position);
    this.models["deck"].rotation.x = (Math.PI / 180) * Config.DECK.rotation[0];
    this.scene.add(this.models["deck"]);

    // dock
    const dockGeo = this.genDockGeo();
    const dockMat = new THREE.MeshBasicMaterial({ color: "black" });
    this.models["dock"] = new THREE.Mesh(dockGeo, dockMat);
    this.models["dock"].position.set(...Config.DOCK.position);
    this.models["dock"].rotation.x = (Math.PI / 180) * Config.DOCK.rotation[0];
    this.scene.add(this.models["dock"]);
    // keyboard
  }
  addEnv() {
    const floor = new THREE.Mesh(
      new THREE.PlaneGeometry(4.8, 4.8, 2, 2).rotateX(-Math.PI / 2),
      new THREE.MeshBasicMaterial({
        color: 0x808080,
        transparent: true,
        opacity: 0.25,
      })
    );
    const room = new THREE.LineSegments(
      new BoxLineGeometry(6, 6, 6, 10, 10, 10).translate(0, 3, 0),
      new THREE.LineBasicMaterial({ color: 0x808080 })
    );
    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
    hemiLight.position.set(0, 200, 0);
    const dirLight = new THREE.DirectionalLight(0xffffff);
    dirLight.position.set(0, 200, 100);
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 180;
    dirLight.shadow.camera.bottom = -100;
    dirLight.shadow.camera.left = -120;
    dirLight.shadow.camera.right = 120;

    this.scene.add(dirLight);
    this.scene.add(hemiLight);
    this.scene.add(room);
    this.scene.add(floor);
  }

  addDataPanel() {
    this.panel = new Control();
    this.panel.onChange((event) => {
      // TODO: 绑定数据与模型
      console.log(event);
      switch (event.property) {
        case "Deck H":
          // Deck 高度
          this.models["deck"].position.y = event.value;
          break;
        case "Deck Rotation":
          this.models["deck"].rotation.x = (event.value * Math.PI) / 180;
          break;
        case "Deck Distance":
          this.models["deck"].position.z = event.value;
          break;
        case "Deck Size":
        case "Deck Aspect":
        case "Deck Bending":
          // this.models["deck"].geometry.width = event.value;
          let geo = this.genDeckGeo();
          this.models["deck"].geometry.dispose();
          this.models["deck"].geometry = geo;
          break;
        // Dock
        case "Dock H":
          this.models["dock"].position.y = event.value;
          break;
        case "Dock Distance":
          this.models["dock"].position.z = event.value;
          break;
        case "Dock Rotation":
          this.models["dock"].rotation.x = (event.value * Math.PI) / 180;
          break;
        case "Dock Size":
        case "Dock Aspect":
          let dockGeo = this.genDockGeo();
          this.models["dock"].geometry.dispose();
          this.models["dock"].geometry = dockGeo;
          break;

        // reach envelope
        case "Show Reach Envelope":
          this.models["reachLeft"].visible = event.value;
          this.models["reachRight"].visible = event.value;
          break;
        default:
          console.log(`Unbinded property ${event.property}`);
          break;
      }
    });
  }

  loadScene() {
    this.objLoader = new THREE.ObjectLoader();
    this.objLoader.load("scene_v5.json", (obj) => {
      window.debug = { obj: obj };
      this.scene.add(obj);
      obj.children.forEach((mesh) => {
        this.models[mesh.name] = mesh;
      });
      window.debug["models"] = this.models;
    });
  }
}

const xrscene = new XRScene();
xrscene.animate();

const Config = {
  EYECAM: {
    eyeCamFOV: 110,
  },
  HUMAN: {
    shoulder: {
      leftPos: [0.17, 1.45, 0],
      rightPos: [-0.17, 1.45, 0],
    },
    eye: {
      leftPos: [0.03, 1.7, 0.1],
      rightPos: [-0.03, 1.7, 0.1],
    },
    arm: {
      length: 0.7,
    },
  },
  DECK: {
    position: [0, 1.5, 1.5],
    rotation: [15, 0, 0],
    width: 2.0,
    height: 1.0,
    depth: 0.01,
  },
  DOCK: {
    position: [0, 0.75, 1.0],
    rotation: [15, 0, 0],
    width: 1.0,
    height: 0.2,
    depth: 0.01,
  },
};

export { Config };

import { GUI } from "lil-gui";

class Control extends GUI {
  constructor() {
    super();

    this._data = {
      ui: {
        "Show Reach Envelope": true,
      },
      human: {
        身高: 1.7,
        臂展: 0.5,
        性别: "F",
        FOV: 110,
        IPD: 60,
      },
      deck: {
        "Deck Size": 2.0,
        "Deck Aspect": 2,
        "Deck Bending": 0,

        "Deck H": 1.5,
        "Deck Distance": 1.5,
        "Deck Rotation": 10,
      },
      dock: {
        "Dock H": 0.75,
        "Dock Distance": 0.75,
        "Dock Rotation": 15,
        "Dock Size": 1,
        "Dock Aspect": 5,
      },
    };
    this.add(this._data.ui, "Show Reach Envelope", true);
    this.addHumanParams();
    this.addDeckParams();
    this.addDockParams();
  }

  addHumanParams() {
    let _folder = this.addFolder("人体参数");
    _folder.add(this._data.human, "性别", ["F", "M"]);
    _folder.add(this._data.human, "身高", 1, 2, 0.1);
    _folder.add(this._data.human, "臂展", 0.5, 1, 0.1);
    let _subfolder = _folder.addFolder("眼睛");
    _subfolder.add(this._data.human, "FOV", 110, 120, 1);
    _subfolder.add(this._data.human, "IPD", 55, 75, 1);
  }
  addDeckParams() {
    let _folder = this.addFolder("Deck 设计参数");
    _folder.add(this._data.deck, "Deck H", this._data.deck["Deck H"], 2.0, 0.1);
    _folder.add(
      this._data.deck,
      "Deck Rotation",
      this._data.deck["Deck Rotation"],
      20,
      1
    );
    _folder.add(
      this._data.deck,
      "Deck Distance",
      this._data.deck["Deck Distance"],
      2.0,
      0.1
    );
    _folder.add(
      this._data.deck,
      "Deck Size",
      this._data.deck["Deck Size"],
      3,
      0.1
    );
    _folder.add(
      this._data.deck,
      "Deck Aspect",
      this._data.deck["Deck Aspect"],
      3.0,
      0.1
    );
    _folder.add(this._data.deck, "Deck Bending", -Math.PI / 2, 0, 0.01);
  }
  addDockParams() {
    let _folder = this.addFolder("Dock 设计参数");
    _folder.add(this._data.dock, "Dock Rotation", 15, 45, 1);
    _folder.add(this._data.dock, "Dock H", 0.75, 1.5, 0.05);
    _folder.add(this._data.dock, "Dock Distance", 0.75, 2, 0.1);
    _folder.add(this._data.dock, "Dock Size", 1.0, 2, 0.1);
    _folder.add(this._data.dock, "Dock Aspect", 5, 10, 1);
  }
}

export { Control };
